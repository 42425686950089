<template>
  <forgot-password />
</template>

<script>
import ForgotPassword from "@/components/login/ForgotPassword.vue";

export default {
  components: {
    ForgotPassword
  },
  mounted() {}
};
</script>
