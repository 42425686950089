<template>
  <v-container fluid fill-height>
    <v-card flat height="auto" width="300px" style="margin: 0 auto 0 auto">
      <v-card flat>
        <div v-if="!getSuccess">
          <div
            class="pb-5"
            style="text-align: center"
            id="forgot-password-info"
          >
            <span>Enter your email and a new password</span>
          </div>
          <v-row no-gutters justify="center">
            <v-form v-model="isFormValid">
              <v-text-field
                filled
                hide-details
                prepend-inner-icon="mdi-email"
                id="create-account-email"
                label="Email"
                :rules="emailRules"
                v-model="email"
                style="margin: 10px; margin-top: 0 !important; padding: 0px"
              />
              <v-text-field
                filled
                hide-details
                id="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-inner-icon="mdi-lock"
                label="Password"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                style="margin: 10px; padding: 0px"
              />

              <v-text-field
                filled
                hide-details
                id="password-confirm"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-inner-icon="mdi-lock"
                label="Password"
                v-model="password_confirm"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                style="margin: 10px; padding: 0px"
              />
            </v-form>
          </v-row>
          <div
            v-if="getError"
            style="text-align: center"
            id="forgot-password-sent-success"
          >
            <p style="color: red; margin-top: 10px">
              {{
                getError === "Passwords don't match"
                  ? "Passwords don't match"
                  : getError === "Email address not found"
                  ? "Email address not found"
                  : "Something went wrong, please try again later"
              }}
            </p>
          </div>
          <v-row no-gutters>
            <v-btn
              @click="submitPassword"
              large
              :disabled="!isFormValid"
              color="primary"
              id="forgot-password-send-email-button"
              style="width: 100%"
              >Change password</v-btn
            >
          </v-row>
        </div>
        <div
          v-else-if="getSuccess"
          style="text-align:center;"
          id="forgot-password-sent-success"
        >
          <span
            >Your password has been changed</span
          >
          <v-row no-gutters class="pt-5">
            <v-btn
              @click="goBack"
              large
              color="light-green"
              style="width: 100%;"
              >Login</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// eslint-disable-next-line no-unused-vars
import router from "../../router";
export default {
  data() {
    return {
      email: null,
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
      isFormValid: false,
      password: null,
      password_confirm: null,
      showPassword: false,
    };
  },
  mounted() {
    this.setError(null);
  },
  methods: {
    ...mapActions({
      _reset_password: "auth/reset_password",
    }),
    ...mapMutations({
      setError: "auth/setError",
      setSuccess: "auth/setSuccess",
    }),
    goBack() {
      router.push("/login");
      this.setError(null);
      this.setSuccess(false);
    },
    submitPassword() {
      let data = {
        email: this.email,
        password_code: this.$route.query.code,
        password: this.password,
        password_confirm: this.password_confirm,
      };
      this._reset_password(data);
    },
  },
  computed: {
    ...mapGetters({
      getError: "auth/getError",
      getSuccess: "auth/getSuccess",
    }),
  },
};
</script>

<style></style>
