<template>
  <v-container fluid fill-height>
    <v-card flat height="auto" width="300px" style="margin: 0 auto 0 auto">
      <v-card flat>
        <div v-if="!getSuccess || getError">
          <div
            class="pb-5"
            style="text-align:center;"
            id="forgot-password-info"
          >
            <span
              >Please enter your email. We will send you an email to reset your
              password.</span
            >
          </div>
          <v-row no-gutters justify="center">
            <v-form v-model="isFormValid">
              <v-text-field
                filled
                hide-details
                prepend-inner-icon="mdi-email"
                id="forgot-password-email"
                :rules="emailRules"
                label="your@example.com"
                v-model="email"
                style="margin: 10px; margin-top: 0 !important; padding: 0px;"
              />
            </v-form>
          </v-row>
          <v-row class="d-flex justify-center">
            <h4 style="color: red;">{{ getError }}</h4>
          </v-row>
          <v-row no-gutters class="pt-5">
            <v-btn
              @click="submitPassword"
              large
              :disabled="!isFormValid"
              color="primary"
              id="forgot-password-send-email-button"
              style="width: 100%;"
              >Send Email</v-btn
            >
          </v-row>
        </div>
        <div
          v-else-if="getSuccess"
          style="text-align:center;"
          id="forgot-password-sent-success"
        >
          <span
            >A code has been sent to your email to allow you to change your
            password. Please follow the instructions to reset your
            password.</span
          >
          <v-row no-gutters class="pt-5">
            <v-btn
              @click="goBack"
              large
              color="light-green"
              style="width: 100%;"
              >Done</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// eslint-disable-next-line no-unused-vars
import router from "../../router";
export default {
  data() {
    return {
      clicked: false,
      email: "",
      isFormValid: false,
      emailRules: [v => /.+@.+/.test(v) || "Invalid Email address"]
    };
  },
  mounted() {
    this.setError(null);
  },
  methods: {
    ...mapActions({
      _forgotPassword: "auth/forgotPassword"
    }),
    ...mapMutations({
      setError: "auth/setError",
      setSuccess: "auth/setSuccess"
    }),
    goBack() {
      router.push("/login");
      this.setError(null);
      this.setSuccess(false);
    },
    submitPassword() {
      this.setError(null);
      this._forgotPassword(this.email);
      this.clicked = true;
    }
  },
  computed: {
    ...mapGetters({
      getError: "auth/getError",
      getSuccess: "auth/getSuccess"
    })
  }
};
</script>

<style></style>
